import ReactModal from "react-modal"

type UpgradeToProModalProps = ReactModal.Props & {
  customerTeam: string
}

const stripeKey = "test_6oEdRy5Bs00R2v66op"

export const UpgradeToProModal = (props: UpgradeToProModalProps) => {
  const handleUpgradeToProClick = () => {
    window.location.assign(
      `https://buy.stripe.com/${stripeKey}?client_reference_id=${props.customerTeam}`
    )
  }

  return (
    <ReactModal
      {...props}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: 10,
          zIndex: 10,
          borderRadius: 5,
          bottom: "auto",
          width: 400,
        },
        overlay: {
          zIndex: 10,
          background: "rgba(0,0,0,0.1)",
        },
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #d3d3d399",
          marginBottom: 25,
          paddingBottom: 5,
        }}
      >
        Your subscription is expired.
      </div>
      <button
        className="api-button"
        onClick={handleUpgradeToProClick}
        style={{ marginTop: 5, marginRight: 10 }}
      >
        Upgrade To Pro
      </button>
      <button
        className="api-button"
        onClick={props.onRequestClose}
        style={{ marginTop: 5 }}
      >
        Close
      </button>
    </ReactModal>
  )
}
