import clsx from "clsx"
import { UISheet } from "../types/UITypes"
import MatrixPreviewRenderer from "./sheet-renderer/MatrixPreviewRenderer"
import SheetPreviewRenderer from "./sheet-renderer/SheetPreviewRenderer"
import { CanvasPermission } from "../utils/constants"
import { useState } from "react"

type PublishApiModalProps = {
  open: boolean
  onClose: () => void
  onPublishToFirebase: () => void
  onPublish: (deploymentEnv: string) => Promise<void>
  userRole: string
  canvasPermissions: Record<string, boolean>
  apiPreview: any
  canvasId: string
}

export const PublishApiModal = (props: PublishApiModalProps) => {
  const {
    open,
    onClose,
    userRole,
    onPublishToFirebase,
    onPublish,
    canvasPermissions,
    apiPreview,
    canvasId,
  } = props

  const [isDeployButtonDisabled, setIsDeployButtonDisabled] = useState(false)

  const [
    isPublishSuccessIndicatorVisible,
    setIsPublishSuccessIndicatorVisible,
  ] = useState(false)

  const [deploymentEnv, setDeploymentEnv] = useState("staging")

  const handlePublish = async () => {
    setIsDeployButtonDisabled(true)

    await onPublish(deploymentEnv)

    setIsDeployButtonDisabled(false)

    setIsPublishSuccessIndicatorVisible(true)

    setTimeout(() => setIsPublishSuccessIndicatorVisible(false), 5000)
  }

  if (!open) {
    return null
  }

  return (
    <>
      <div onClick={onClose} className="overlay"></div>
      <div className="modal">
        {(userRole === "dev" || userRole === "superuser") && (
          <div className="deploy-controls">
            <div
              className={clsx("deploy-success-indicator", {
                "deploy-success-indicator-visible":
                  isPublishSuccessIndicatorVisible,
              })}
            >
              Published
            </div>
            <div className="deploy-controls-spacer" />
            {canvasPermissions[CanvasPermission.FirebaseExport] && (
              <button
                onClick={onPublishToFirebase}
                className={clsx("api-button", {
                  "disabled-button": isDeployButtonDisabled,
                })}
                disabled={isDeployButtonDisabled}
                style={{ marginRight: 10 }}
              >
                Export to Firebase
              </button>
            )}
            {canvasPermissions[CanvasPermission.CloudflareExport] && (
              <>
                <button
                  onClick={handlePublish}
                  className={clsx("api-button", {
                    "disabled-button": isDeployButtonDisabled,
                  })}
                  disabled={isDeployButtonDisabled}
                >
                  Publish
                </button>

                <div className="deploy-controls-spacer" />
                <select
                  onChange={({ target }) => setDeploymentEnv(target.value)}
                  value={deploymentEnv}
                >
                  <option value="dev">Dev</option>
                  <option value="staging">Staging</option>
                  <option value="prod">Production</option>
                </select>
              </>
            )}
            <div className="deploy-controls-spacer" />
            <button onClick={onClose} className="view-api-button api-button">
              Hide Preview
            </button>
          </div>
        )}
        {apiPreview?.map((apiPreviewItem: any, i: number) => (
          <div
            key={`apiPreview-${apiPreviewItem.sheetId}`}
            style={{
              padding: 40,
              paddingTop: i === 0 ? 0 : 40,
              borderBottom:
                i + 1 < apiPreview.length ? "1px solid #ccc" : "none",
            }}
          >
            {canvasPermissions[CanvasPermission.CloudflareExport] && (
              <div style={{ marginBottom: 20 }}>
                <b>API URL:</b>
                <div
                  style={{
                    fontFamily: "monospace",
                    fontSize: 14,
                    marginTop: 5,
                  }}
                >{`https://canvas-logic-${canvasId}-${deploymentEnv}.fyngo.workers.dev/`}</div>
              </div>
            )}
            <div style={{ marginBottom: 20 }}>
              <b>API Name:</b> {apiPreviewItem.name}
            </div>
            <div>
              <b>Request Payload:</b>
            </div>
            <pre>{JSON.stringify(apiPreviewItem?.requestPayload, null, 2)}</pre>
            <div style={{ marginTop: 20, marginBottom: 8 }}>
              <b>Response Preview:</b>
            </div>
            {(apiPreviewItem.sheetData?.data as UISheet).complex ? (
              <MatrixPreviewRenderer
                id={`${apiPreviewItem.sheetId}`}
                data={apiPreviewItem.sheetData?.data as UISheet}
                key={`${apiPreviewItem.sheetId}`}
              />
            ) : (
              <SheetPreviewRenderer
                id={`${apiPreviewItem.sheetId}`}
                data={apiPreviewItem.sheetData?.data as UISheet}
                key={`${apiPreviewItem.sheetId}`}
              />
            )}

            <button
              style={{ marginTop: 20 }}
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `curl -d '${JSON.stringify(
                      apiPreviewItem.requestPayload
                    )}' -u admin:admin -H "Content-Type: application/json" -X POST https://canvas-logic-${canvasId}-${deploymentEnv}.fyngo.workers.dev/`
                  )
                  .then(
                    function () {
                      alert("cURL is copied to clipboard")
                    },
                    function () {
                      alert("Could not copy cURL to clipboard")
                    }
                  )
              }}
            >
              Export
            </button>
          </div>
        ))}
      </div>
    </>
  )
}
