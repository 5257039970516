import { useState } from "react"
import { useDropzone } from "react-dropzone"
import ReactModal from "react-modal"
import TextareaAutosize from "react-textarea-autosize"
import { ReactComponent as RedCrossIcon } from "../assets/icons/red-cross-icon.svg"

type AutogenModalProps = ReactModal.Props & {
  isLoadingAIResponse: boolean
  onConfirm: (autogeneratePrompt: string, filesToUpload: File[]) => void
}

export const AutogenModal = (props: AutogenModalProps) => {
  const { isLoadingAIResponse, onConfirm, ...reactModalProps } = props

  const [autogeneratePrompt, setAutogeneratePrompt] = useState("")
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])

  const handleConfirm = () => {
    onConfirm(autogeneratePrompt, filesToUpload)
    setAutogeneratePrompt("")
    setFilesToUpload([])
  }

  const handleFileUploadDrop = (files: File[]) => {
    setFilesToUpload(files)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    onDrop: handleFileUploadDrop,
  })

  return (
    <ReactModal
      {...reactModalProps}
      style={{
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: 10,
          zIndex: 10,
          borderRadius: 5,
          bottom: "auto",
        },
        overlay: {
          zIndex: 10,
          background: "rgba(0,0,0,0.1)",
        },
      }}
    >
      {isLoadingAIResponse && (
        <div className="loading-indicator">
          <span className="loading-indicator-span-1"></span>
          <span className="loading-indicator-span-2"></span>
        </div>
      )}

      {!isLoadingAIResponse && (
        <div style={{ height: "calc(100% - 40px)" }}>
          <TextareaAutosize
            style={{
              display: "block",
              border: "1px solid #898989",
              borderRadius: 5,
              width: "calc(100% - 5px)",
              color: "#333",
            }}
            minRows={8}
            onChange={(event) => setAutogeneratePrompt(event.target.value)}
          />
          <section className="file-upload-container">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {filesToUpload.length ? (
              <aside>
                <h4>Upload files:</h4>
                <div>
                  {filesToUpload.map((file, i) => (
                    <div
                      key={file.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10,
                      }}
                    >
                      {file.name}
                      <span
                        style={{
                          fontSize: 12,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setFilesToUpload(
                            filesToUpload.filter(
                              (_, fileIndex) => fileIndex !== i
                            )
                          )
                        }
                      >
                        <RedCrossIcon
                          style={{
                            width: 14,
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                        />
                        Remove
                      </span>
                    </div>
                  ))}
                </div>
              </aside>
            ) : null}
          </section>
          <button
            className="api-button"
            onClick={handleConfirm}
            style={{ marginTop: 5, marginRight: 10 }}
          >
            Autogenerate
          </button>
        </div>
      )}
    </ReactModal>
  )
}
