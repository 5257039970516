import { Database } from "./SupabaseTypes"

export type DatabaseCell = Database["public"]["Tables"]["cell"]["Row"]
export type DatabaseSheet = Database["public"]["Tables"]["sheet"]["Row"]
export type DatabaseEdge = Database["public"]["Tables"]["edge"]["Row"]
export type DatabaseLogic = Database["public"]["Tables"]["logic"]["Row"]
export type DatabaseCanvas = Database["public"]["Tables"]["canvas"]["Row"]
export type DatabaseColumnLabel =
  Database["public"]["Tables"]["column_label"]["Row"]
export type DatabaseRowLabel = Database["public"]["Tables"]["row_label"]["Row"]
export type DatabaseColumnLabelsLabel =
  Database["public"]["Tables"]["column_label_label"]["Row"]
export type DatabaseRowLabelsLabel =
  Database["public"]["Tables"]["row_label_label"]["Row"]
export type CustomerSettings =
  Database["public"]["Tables"]["customer_settings"]["Row"]

export enum CellType {
  Number = 1,
  Text = 2,
  Date = 3,
}

export const CellTypeLabels: Record<string, string> = {
  [CellType.Number]: "Number",
  [CellType.Text]: "Text",
  [CellType.Date]: "Date",
}
